import React from "react";
import { Link } from "gatsby";
import cs from "classnames";

import styles from "./list.module.scss";

type NavItem = {
  title: string;
  url: string;
};

type ListProps = {
  items: Array<NavItem & { subItems?: NavItem[] }>;
  visible?: boolean;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
};

export const List: React.FC<ListProps> = ({ items, visible, onClick }) => (
  <div className={cs(styles.wrapper, visible && styles.visible)}>
    {items.map(({ title, url, subItems }, i) => (
      <div key={i} className={styles.item}>
        <Link to={url} onClick={onClick}>
          {title}
        </Link>
        {subItems && (
          <div className={styles.subItemWrapper}>
            {subItems.map(({ title, url }, i) => (
              <div key={i} className={styles.subItem}>
                <Link to={url} onClick={onClick}>
                  {title}
                </Link>
              </div>
            ))}
          </div>
        )}
      </div>
    ))}
  </div>
);
