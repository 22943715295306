import React from "react";

import { Button } from "./button";
import { List } from "./list";

const navItems = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "Café Paris",
    url: "/cafe-paris",
  },
  {
    title: "Speisekarten",
    url: "/speisekarten",
    subItems: [
      { title: "Petit Café", url: "/speisekarten/petit-cafe" },
      { title: "Kultur Café Paris", url: "/speisekarten/cafe-paris" },
    ],
  },
  {
    title: "Galerie",
    url: "/galerie",
  },
  {
    title: "Kontakt",
    url: "/kontakt",
  },
];

export const Nav: React.FC = () => {
  const [opened, setOpened] = React.useState(false);

  function handleClick() {
    setOpened(!opened);
  }

  function handleAction() {
    setOpened(false);
  }

  return (
    <nav>
      <Button active={opened} onClick={handleClick} />
      <List items={navItems} visible={opened} onClick={handleAction} />
    </nav>
  );
};
