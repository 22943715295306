import React from "react";
import { Link } from "gatsby";

import styles from "./footer.module.scss";
import facebookIconUrl from "../../../images/facebook.svg";

export const Footer: React.FC = () => (
  <footer className={styles.wrapper}>
    <div className={styles.innerWrapper}>
      <div className={styles.outerFlex}>
        <div className={styles.innerFlex}>
          <div>
            <strong>Petit Café</strong>
            <br />
            Marktstr. 58
            <br />
            47798 Krefeld
          </div>
          <div>
            <strong>Kultur Café Paris</strong>
            <br />
            Theaterplatz 2
            <br />
            47798 Krefeld
          </div>
        </div>
        <div className={styles.innerFlex}>
          <div>
            <Link to="/impressum">Impressum</Link>
            {/* <Link to="/datenschutz">Datenschutz</Link> */}
          </div>
          <div>
            <a
              href="https://www.facebook.com/Caf%C3%A9-Paris-417127952173100/"
              className={styles.socialMedia}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={facebookIconUrl}
                draggable={false}
                alt="Facebook Logo"
              />
            </a>
          </div>
        </div>
      </div>
      <div className={styles.copyright}>
        © {new Date().getFullYear()} Simply Food
      </div>
    </div>
  </footer>
);
