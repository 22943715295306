import React from "react";
import { Link } from "gatsby";

import { Nav } from "./nav";

import logoUrl from "../../../images/simplyfood-logo.svg";
import styles from "./header.module.scss";

export const Header: React.FC = () => {
  return (
    <header className={styles.wrapper}>
      <Link to="/" className={styles.siteLink}>
        <img src={logoUrl} draggable={false} alt="Simply Food Logo" />
      </Link>
      <Nav />
    </header>
  );
};
