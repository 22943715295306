import React from "react";

import { Header } from "../header";
import { Footer } from "../footer";
import styles from "./layout.module.scss";

import "../../global.scss";

/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 */

export const Layout: React.FC = ({ children }) => (
  <>
    <Header />
    <div className={styles.contentOffset}>
      <main>{children}</main>
      <Footer />
    </div>
  </>
);
