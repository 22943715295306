import React from "react";
import ReactHelmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

type MetaObjectType = React.DetailedHTMLProps<
  React.MetaHTMLAttributes<HTMLMetaElement>,
  HTMLMetaElement
>[];

type HelmetProps = {
  description?: string;
  meta?: MetaObjectType;
  title?: string;
};

/**
 * SEO component that queries for data with
 * Gatsby's useStaticQuery React hook
 */

export const Helmet: React.FC<HelmetProps> = ({ description, meta, title }) => {
  const {
    site: { siteMetadata },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  const metaDescription: string = description || siteMetadata.description;

  let metaArray: MetaObjectType = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:creator`,
      content: siteMetadata.author,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
  ];

  if (meta) metaArray = [...metaArray, ...meta];

  return (
    <ReactHelmet
      htmlAttributes={{
        lang: "de",
      }}
      title={title || siteMetadata.title}
      titleTemplate={title && `%s | ${siteMetadata.title}`}
      meta={metaArray}
    />
  );
};
