import React from "react";
import cs from "classnames";

import styles from "./button.module.scss";

type ButtonProps = {
  active?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export const Button: React.FC<ButtonProps> = ({ active, onClick }) => (
  <button
    className={cs(styles.wrapper, active && styles.active)}
    onClick={onClick}
  >
    <div />
    <div />
    <div />
  </button>
);
